import PropTypes from 'prop-types';
import { useCallback, useContext, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Comment from 'OK/components/comments/comment';
import { CommentsContext } from 'OK/components/comments/controller';
import CommentsInput from 'OK/components/comments/input';
import CommentModel from 'OK/models/comment';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * UI component for rendering a thread of comments.
 *
 * @param {object} props
 */
export default function CommentsThread(props) {
  /* Variables */

  const { assetType, assetId, className, thread = [], threadCount = 0 } = props;
  const { inputTargetType, inputTargetId, loadPageOfCommentThread, setInputTarget, rootAssetId, rootAssetType } =
    useContext(CommentsContext);
  const { t } = useI18n();
  const nested = assetType === 'COMMENT';
  const currentPage = Math.max(parseInt(thread.length / CommentModel.DEFAULT_THREAD_SIZE, 10) - 1, 0); // Page is zero-based

  /* State */

  const [loadingMore, setLoadingMore] = useState(false);

  /* Methods */

  const loadNextPageOfThread = useCallback(() => {
    setLoadingMore(true);
    loadPageOfCommentThread(assetType, assetId, currentPage + 1).finally(() => {
      setLoadingMore(false);
    });
  }, [assetId, assetType, currentPage, loadPageOfCommentThread]);

  const resetInputTarget = useCallback(() => {
    setInputTarget(rootAssetType, rootAssetId);
  }, [rootAssetId, rootAssetType, setInputTarget]);

  /* Render */

  // Conditional render logic
  const shouldRenderLoadThreadButton = threadCount > thread.length;
  const shouldRenderInput = inputTargetType === assetType && inputTargetId === assetId;

  // Container classes
  let classNames = '';
  if (className) {
    classNames += ` ${className}`;
  }

  // Position classes
  let positioningClassNames = styles.positioningContainer;
  if (nested && (thread.length > 0 || shouldRenderInput)) {
    positioningClassNames += ` ${styles.nested}`;
  }

  // Load more button classes
  let loadMoreButtonClassNames = styles.loadMoreButton;
  if (shouldRenderInput) {
    loadMoreButtonClassNames += ` ${styles.inputSpacing}`;
  }

  return (
    <div className={classNames}>
      <div className={positioningClassNames}>
        {thread.map((c, index) => {
          let commentClassNames = styles.comment;
          const isLastChild = index === thread.length - 1;
          const inputIsTargetingThisComment = inputTargetType === 'COMMENT' && inputTargetId === c.id;
          if (isLastChild && !inputIsTargetingThisComment) {
            commentClassNames += ` ${styles.lastChild}`;
          }
          return (
            <Comment
              className={commentClassNames}
              key={c.id}
              comment={c}
              replyTargetAssetType={assetType}
              replyTargetAssetId={assetId}
            />
          );
        })}
        {shouldRenderLoadThreadButton && (
          <Button
            className={loadMoreButtonClassNames}
            disabled={loadingMore}
            loading={loadingMore}
            onClick={loadNextPageOfThread}
          >
            {t('COMMENT_BUTTON_SHOW_MORE_REPLIES')}
          </Button>
        )}
        {assetType !== 'COMMENT' && !shouldRenderInput && (
          <Button className={styles.newCommentButton} onClick={resetInputTarget}>
            {t('COMMENT_BUTTON_POST_NEW_COMMENT')}
          </Button>
        )}
        <CommentsInput replyTargetAssetType={assetType} replyTargetAssetId={assetId} inProp={shouldRenderInput} />
      </div>
    </div>
  );
}

CommentsThread.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  className: PropTypes.string,
  thread: PropTypes.array,
  threadCount: PropTypes.number,
};
