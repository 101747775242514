import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import LoadingSpinner from '../loadingSpinner';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';

import AudioPlayer from 'OK/components/audioPlayer';
import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel/old';
import TextLayout from 'OK/components/layouts/content/text';
import ImageCropper from 'OK/components/media/editors/imageCropper';
import MediaPicker from 'OK/components/mediaPicker';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import VideoPlayer from 'OK/components/videoPlayer';
import MediaAssetModel from 'OK/models/mediaAsset';
import { trackError } from 'OK/util/analytics';
import UIContext from 'OK/util/context/ui';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Display media in a gallery view.
 *
 * @param {object} props
 * @param {array} [props.allowAddingMediaTypes] Media types that can be add to the gallery. Will be passed to the
 * underlying `MediaPicker`. Defaults to allow 'photo', 'video', and 'audio'.
 * @param {string} [props.className] The gallery class.
 * @param {boolean} [props.enableEditing=false] Allow editing and reordering of media.
 * @param {array} props.media Array of MediaAsset objects.
 * @param {(status: boolean) => void} [props.onChangeEditingStatus] Event handler function when the user has started or ended editing media.
 * @param {(mediaAssetId: string) => void} [props.onClickUnlink] Event handler function when the user clicks to unlink media.
 * @param {(mediaAssetId: string, file: File) => void} [props.onMediaFileUpdated] Event handler function when a media file is updated.
 * @param {(files: File[]) => void} [props.onNewMediaSelected] Event handler function when the user selects media to add to the gallery.
 * @param {(mediaAssetId: string, reorderDirection: ('left'|'right'), reorderDistance: number) => void} [props.onMediaReordered] Event handler function when media is re-ordered.
 */
const MediaGallery = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    allowAddingMediaTypes = ['photo', 'video', 'audio'],
    className,
    enableEditing = false,
    error,
    media = [],
    mediaLoading,
    onChangeEditingStatus,
    onClickUnlink,
    onMediaReordered,
    onMediaFileUpdated,
    onNewMediaSelected,
    previewsClassName,
    showMediaDetails = true,
    showThumbnails = true,
    warning,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const containerDivRef = useRef();
  const mediaPickerRef = useRef();
  const sliderRef = useRef();
  const uiContext = useContext(UIContext);

  /* State */

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const currentMedia = media.length ? media[currentMediaIndex] : null;
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingPickedMedia, setEditingPickedMedia] = useState(null);
  const [galleryWidth, setGalleryWidth] = useState(0);
  const [mediaPickerError, setMediaPickerError] = useState(null);

  /* Methods */

  const nextMedia = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentMediaIndex < media.length - 1) {
      const nextSlideIndex = currentMediaIndex + 1;
      scrollToMediaAtIndex(nextSlideIndex);
    }
  };

  const openMediaPicker = () => {
    try {
      mediaPickerRef.current.open();
    } catch (e) {
      trackError(e);
    }
  };

  const previousMedia = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentMediaIndex > 0) {
      const previousSlideIndex = currentMediaIndex - 1;
      scrollToMediaAtIndex(previousSlideIndex);
    }
  };

  const reorder = (reorderDirection, reorderDistance = 1) => {
    if (onMediaReordered) {
      onMediaReordered(currentMedia.id, reorderDirection, reorderDistance);
    }
  };

  const resetEditingIndex = () => {
    onChangeEditingStatus && onChangeEditingStatus(false);
    setEditingIndex(-1);
  };

  const scrollToMediaAtIndex = (index) => {
    const slider = sliderRef.current;
    slider?.scrollToSlideAtIndex(index);
  };

  /* Events */

  const onActiveMediaChanged = (activeIndexes) => {
    const activeIndex = activeIndexes[0];
    setCurrentMediaIndex(activeIndex);
  };

  const onEditingPickedMedia = (pickedMediaFileType) => {
    setEditingPickedMedia(simpleMIMEType(pickedMediaFileType));
    onChangeEditingStatus && onChangeEditingStatus(true);
    setMediaPickerError(null);
  };

  const onMediaEdited = (mediaAssetId, file) => {
    resetEditingIndex();
    if (onMediaFileUpdated) {
      onMediaFileUpdated(mediaAssetId, file);
    }
  };

  const onMediaPicked = (files, originalFiles) => {
    onNewMediaSelected(files, originalFiles);
    setMediaPickerError(null);
  };

  const onMediaPickerError = (error) => {
    setMediaPickerError(t(error, { fallback: 'ERROR_GENERIC' }));
  };

  /* Effects */

  // Ensure currentMediaIndex is valid for the number of media being rendered.
  // This is important when viewing the last media element and then it is removed because then we need to update
  // the currentMediaIndex to ensure no index out of bounds errors.
  useEffect(() => {
    const maxPossibleIndex = Math.max(media.length - 1, 0);
    if (currentMediaIndex > maxPossibleIndex) {
      // The last media in the list was being viewed and then got removed, so display the new last media.
      setCurrentMediaIndex(maxPossibleIndex);
    }
  }, [currentMediaIndex, media.length]);

  // Customize ref interface
  useImperativeHandle(forwardedRef, () => ({
    next: () => nextMedia(),
    openMediaPicker: () => openMediaPicker(),
    previous: () => previousMedia(),
    scrollToIndex: (i) => scrollToMediaAtIndex(i),
  }));

  // Keep track of gallery width
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const latestWidth = containerDivRef.current?.clientWidth;
    if (latestWidth && latestWidth !== galleryWidth) {
      setGalleryWidth(latestWidth);
    }
  });

  /* Render */

  const activeMediaIsFirst = currentMediaIndex === 0;
  const activeMediaIsLast = currentMediaIndex === media.length - 1;
  const hasMultipleMedia = media.length > 1;
  const renderGallery = media.length && currentMedia;
  const renderReorderUI = enableEditing && hasMultipleMedia;

  let currentMediaType;
  if (editingPickedMedia) {
    currentMediaType = editingPickedMedia;
  } else if (currentMedia?.mediaType) {
    currentMediaType = currentMedia.mediaType;
  } else if (currentMedia?.versionedMediaAsset || currentMedia?.unversionedMediaAsset) {
    currentMediaType = (currentMedia.versionedMediaAsset ?? currentMedia.unversionedMediaAsset).mediaType;
  }

  let currentMediaREFID;
  if (currentMedia?.REFID) {
    currentMediaREFID = currentMedia.REFID;
  } else if (currentMedia?.versionedMediaAsset) {
    currentMediaREFID = currentMedia.versionedMediaAsset.REFID;
  }

  const mediaPicker = (
    <MediaPicker
      className={`${styles.mediaPicker} ${renderGallery ? '' : className}`}
      invisible
      mediaTypes={allowAddingMediaTypes}
      onBeganEditingMedia={onEditingPickedMedia}
      onEndedEditingMedia={() => {
        setEditingPickedMedia(null);
        onChangeEditingStatus && onChangeEditingStatus(false);
      }}
      onError={onMediaPickerError}
      onChange={onMediaPicked}
      ref={mediaPickerRef}
    />
  );

  // Don't render any gallery if there is no media or if currentMedia is null (this can occur for a brief moment when
  // viewing the last media and it is removed. currentMediaIndex will update to a valid value in the effect above).
  if (!renderGallery) {
    return mediaPicker;
  }

  // Classes
  let classNames = styles.gallery;
  let tintStyles;
  switch (uiContext) {
    case 'card':
      tintStyles = cardStyles;
      break;
    default:
      tintStyles = baseStyles;
      break;
  }
  if (showMediaDetails) {
    classNames = `${classNames} ${styles.withMediaDetails}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let previewsClassNames = styles.previews;
  if (previewsClassName) {
    previewsClassNames = `${previewsClassNames} ${previewsClassName}`;
  }

  return (
    <div className={classNames} ref={containerDivRef} {...otherProps}>
      {mediaLoading && <LoadingSpinner className={styles.mediaLoadingSpinner} />}
      {!editingPickedMedia && (
        <Carousel
          className={previewsClassNames}
          onActiveSlideChanged={onActiveMediaChanged}
          ref={sliderRef}
          showCustomScrollbar={false}
          showSlideIndicators={false}
          slideWidth={galleryWidth}
        >
          {media.map((m, index) => {
            const { id } = m;
            let mediaAsset;
            if (m.versionedMediaAsset) {
              mediaAsset = m.versionedMediaAsset;
            } else if (m.unversionedMediaAsset) {
              mediaAsset = m.unversionedMediaAsset;
            } else {
              mediaAsset = m;
            }
            const mediaType = mediaAsset.mediaType;
            let mediaComponent;

            console.log("galleryWidth MediaGallery", galleryWidth);
            if (editingIndex !== index) {
              // Normal UI
              switch (mediaType) {
                case MediaAssetModel.MEDIA_TYPE.IMAGE:
                  mediaComponent = <Image src={mediaAsset.imageData.imageURL} mediaLoading />;
                  break;
                case MediaAssetModel.MEDIA_TYPE.VIDEO:
                  mediaComponent = <Video src={mediaAsset.videoData.videoURL} galleryWidth={galleryWidth} />;
                  break;
                case MediaAssetModel.MEDIA_TYPE.AUDIO:
                  mediaComponent = <Audio src={mediaAsset.audioData.audioURL} />;
                  break;
                default:
                  mediaComponent = (
                    <p>
                      {mediaType
                        ? t('MEDIA_GALLERY_X_FILE', { data: { filetype: capitalize(mediaType) } })
                        : t('MEDIA_GALLERY_UNKNOWN_FILE')}
                    </p>
                  );
                  break;
              }
            } else {
              // Editing UI
              switch (mediaType) {
                case MediaAssetModel.MEDIA_TYPE.IMAGE:
                  mediaComponent = (
                    <ImageCropper
                      fileName={mediaAsset.imageData.imageName}
                      fileType={'image/jpeg'}
                      imageUrl={mediaAsset.imageData.baseImageURL}
                      onCancel={resetEditingIndex}
                      onCrop={(file) => onMediaEdited(id, file)}
                    />
                  );
                  break;
                default:
                  mediaComponent = (
                    <p>{t('MEDIA_GALLERY_CANNOT_EDIT_X_FILES', { data: { filetype: mediaType.toLowerCase() } })}</p>
                  );
                  break;
              }
            }

            return (
              <Slide fullWidth key={id}>
                {mediaComponent}
                {enableEditing && editingIndex !== index && (
                  <div className={styles.editingButtons}>
                    <Button icon='/icons/unlink_red.svg' invert onClick={() => onClickUnlink(id)} />
                    {mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE && (
                      <Button
                        icon='/icons/pencil_blue.svg'
                        invert
                        onClick={() => {
                          setEditingIndex(index);
                          onChangeEditingStatus && onChangeEditingStatus(true);
                        }}
                        style={{ width: 140 }}
                        tint='navigation'
                      >
                        {t('EDIT')}
                      </Button>
                    )}
                  </div>
                )}
              </Slide>
            );
          })}
        </Carousel>
      )}
      {mediaPicker}
      {showMediaDetails && (
        <div className={`${styles.mediaInfo} ${tintStyles.mediaInfo} ${renderReorderUI ? '' : styles.bottomRounded}`}>
          <h4 className={styles.mediaType}>{t(currentMediaType)}</h4>
          {!editingPickedMedia && currentMediaREFID && (
            <Tag className={styles.mediaRefId} invert size='sm'>
              {currentMediaREFID}
            </Tag>
          )}
        </div>
      )}
      {renderReorderUI && (
        <div className={`${styles.reorderRow} ${tintStyles.reorderRow}`}>
          <Button
            className={styles.reorderLeftButton}
            disabled={activeMediaIsFirst || editingIndex != -1 || editingPickedMedia ? true : false}
            linkStyle
            icon='/icons/reorder_blue.svg'
            iconPosition='left'
            onClick={() => reorder('left')}
            style={{ opacity: activeMediaIsFirst || editingIndex != -1 || editingPickedMedia ? 0 : 1 }}
          >
            {t('MEDIA_GALLERY_REORDER_LEFT')}
          </Button>
          <p style={{ opacity: editingIndex != -1 || editingPickedMedia ? 0 : 1 }}>{t('MEDIA_GALLERY_REORDER')}</p>
          <Button
            disabled={activeMediaIsLast || editingIndex != -1 || editingPickedMedia}
            linkStyle
            icon='/icons/reorder_blue.svg'
            onClick={() => reorder('right')}
            style={{ opacity: activeMediaIsLast || editingIndex != -1 || editingPickedMedia ? 0 : 1 }}
          >
            {t('MEDIA_GALLERY_REORDER_RIGHT')}
          </Button>
        </div>
      )}
      <TextLayout style={{ padding: 0 }}>
        {(error || warning || mediaPickerError) && (
          <Text className={styles.errorMessage} size='sm' tint={error || mediaPickerError ? 'alert' : 'notification'}>
            {error || warning || mediaPickerError}
          </Text>
        )}
        {showThumbnails && (
          <div className={styles.thumbnails}>
            {media.map((m, index) => {
              const { id } = m;
              let mediaAsset;
              if (m.versionedMediaAsset) {
                mediaAsset = m.versionedMediaAsset;
              } else if (m.unversionedMediaAsset) {
                mediaAsset = m.unversionedMediaAsset;
              } else {
                mediaAsset = m;
              }
              const mediaType = mediaAsset.mediaType;
              let src;
              switch (mediaType) {
                case MediaAssetModel.MEDIA_TYPE.IMAGE:
                  src = mediaAsset.imageData.imageURL;
                  break;
                case MediaAssetModel.MEDIA_TYPE.VIDEO:
                  src = mediaAsset.videoData.videoURL;
                  break;
                case MediaAssetModel.MEDIA_TYPE.AUDIO:
                  src = mediaAsset.audioData.audioURL;
                  break;
                default:
                  break;
              }
              return (
                <div key={id}>
                  <Thumbnail
                    active={currentMediaIndex === index && !editingPickedMedia}
                    key={id}
                    onClick={() => {
                      if (editingIndex == -1) {
                        scrollToMediaAtIndex(index);
                      }
                    }}
                    media={m}
                    mediaType={mediaType}
                    src={src}
                    style={{
                      opacity: (currentMediaIndex !== index && editingIndex != -1) || editingPickedMedia ? 0.5 : 1,
                    }}
                  />
                </div>
              );
            })}
            {enableEditing && (
              <div
                className={`${styles.thumbnailContainer} ${tintStyles.thumbnailContainer} ${
                  editingPickedMedia
                    ? `${styles.active} ${tintStyles.active}`
                    : `${styles.addMedia} ${tintStyles.addMedia}`
                }`}
                {...otherProps}
              >
                <button
                  className={`${styles.thumbnail} ${tintStyles.thumbnail} ${styles.addMediaButton}`}
                  disabled={editingPickedMedia || editingIndex !== -1}
                  onClick={() => {
                    if (editingIndex == -1) {
                      openMediaPicker();
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
        {renderReorderUI && <p className={styles.reorderInstructions}>{t('MEDIA_GALLERY_REORDER_DESCRIPTION')}</p>}
      </TextLayout>
      {hasMultipleMedia && editingIndex == -1 && !editingPickedMedia && (
        <div className={styles.navigationControls}>
          <button
            className={styles.previousMediaButton}
            disabled={activeMediaIsFirst}
            onClick={previousMedia}
            style={{ opacity: activeMediaIsFirst ? 0 : 1 }}
          >
            <img alt={t('IMG_ALT_PREVIOUS_MEDIA')} src='/icons/caret_blue_outlined.svg' />
          </button>
          <button
            className={styles.nextMediaButton}
            disabled={activeMediaIsLast}
            onClick={nextMedia}
            style={{ opacity: activeMediaIsLast ? 0 : 1 }}
          >
            <img alt={t('IMG_ALT_NEXT_MEDIA')} src='/icons/caret_blue_outlined.svg' />
          </button>
        </div>
      )}
    </div>
  );
});

MediaGallery.propTypes = {
  allowAddingMediaTypes: PropTypes.arrayOf(PropTypes.oneOf(['photo', 'video', 'audio'])),
  className: PropTypes.string,
  enableEditing: PropTypes.bool,
  error: PropTypes.string,
  media: PropTypes.array,
  mediaLoading: PropTypes.bool,
  onChangeEditingStatus: PropTypes.func,
  onClickUnlink: PropTypes.func,
  onMediaFileUpdated: PropTypes.func,
  onMediaReordered: PropTypes.func,
  onNewMediaSelected: PropTypes.func,
  previewsClassName: PropTypes.string,
  showMediaDetails: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  warning: PropTypes.string,
};

export default MediaGallery;

/**
 * Gallery audio component.
 *
 * @param {object} props
 * @param {string} props.src The source url for the audio.
 */
function Audio(props) {
  const { src } = props;
  return <AudioPlayer source={src} />;
}

Audio.propTypes = {
  src: PropTypes.string.isRequired,
};

/**
 * Gallery image component.
 *
 * @param {object} props
 * @param {string} props.src The source url for the image.
 */
function Image(props) {
  const { className, src } = props;
  return (
    <div className={`${styles.imageContainer} ${className || ''}`}>
      <div className={styles.image} style={{ backgroundImage: `url(${src})` }} />
    </div>
  );
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};

/**
 * Gallery video component.
 *
 * @param {object} props
 * @param {string} props.src The source url for the video.
 */
function Video(props) {
  const { className, galleryWidth, src } = props;
  return <VideoPlayer className={className} source={src} galleryWidth={galleryWidth} />;
}

Video.propTypes = {
  className: PropTypes.string,
  galleryWidth: PropTypes.number,
  src: PropTypes.string.isRequired,
};

/**
 * A thumbnail representation of media.
 *
 * @param {object} props
 * @param {boolean} [props.active=false] Indicate the thumbnail is the current media being viewed.
 * @param {('IMAGE'|'VIDEO'|'AUDIO')} props.mediaType The type of media to render a thumbnail for.
 * @param {string} [props.src] The URL for the media. Currently only used for image types.
 */
function Thumbnail(props) {
  const { active = false, media, mediaType, src, ...otherProps } = props;
  const uiContext = useContext(UIContext);
  const { t } = useI18n();

  let tintStyles;
  switch (uiContext) {
    case 'card':
      tintStyles = cardStyles;
      break;
    default:
      tintStyles = baseStyles;
      break;
  }

  let mediaElement;
  if (mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE) {
    mediaElement = <Image className={`${styles.thumbnail} ${tintStyles.thumbnail}`} src={src} />;
  } else if (mediaType === MediaAssetModel.MEDIA_TYPE.VIDEO) {
    const hasDisplayimage = media.metadata?.displayImage ? true : false;
    mediaElement = (
      <div>
        <div
          className={`${styles.thumbnail} ${tintStyles.thumbnail} ${styles.videoThumbnail} ${
            hasDisplayimage ? styles.thumbnailOverlay : ''
          }`}
        />
        {media.metadata?.displayImage && (
          <Image className={`${styles.thumbnail} ${tintStyles.thumbnail}`} src={media.metadata.displayImage} />
        )}
      </div>
    );
    // if (media.metadata) {
    //   mediaElement = <Image className={`${styles.thumbnail} ${tintStyles.thumbnail}`} src={media.metadata.displayImage} />;
    // } else {
    //   mediaElement = <div className={`${styles.thumbnail} ${tintStyles.thumbnail} ${styles.videoThumbnail}`} />;
    // }
  } else if (mediaType === MediaAssetModel.MEDIA_TYPE.AUDIO) {
    mediaElement = <div className={`${styles.thumbnail} ${tintStyles.thumbnail} ${styles.audioThumbnail}`} />;
  } else {
    okerror('Unknown media type passed to Thumbnail.');
    mediaElement = <p className={`${styles.thumbnail} ${tintStyles.thumbnail}`}>{t('MEDIA_GALLERY_UNKNOWN')}</p>;
  }

  return (
    <div
      className={`${styles.thumbnailContainer} ${tintStyles.thumbnailContainer} ${
        active ? `${styles.active} ${tintStyles.active}` : ''
      }`}
      {...otherProps}
    >
      {mediaElement}
    </div>
  );
}

Thumbnail.propTypes = {
  active: PropTypes.bool,
  media: PropTypes.any.isRequired,
  mediaType: PropTypes.oneOf([
    MediaAssetModel.MEDIA_TYPE.IMAGE,
    MediaAssetModel.MEDIA_TYPE.VIDEO,
    MediaAssetModel.MEDIA_TYPE.AUDIO,
  ]).isRequired,
  src: PropTypes.string,
};
