import { useLazyQuery, useQuery } from '@apollo/client';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import DateFnsFormatDate from 'date-fns/format';
import dateIsInTheFuture from 'date-fns/isFuture';
import datesAreEqual from 'date-fns/isSameDay';
import lastDayOfYear from 'date-fns/lastDayOfYear';
import startOfYear from 'date-fns/startOfYear';
import subtractFromDate from 'date-fns/sub';
import PropTypes from 'prop-types';
import { Children, forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import DocumentArchiveCard from 'OK/components/document/archiveCard';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import InspectionLogArchiveCard from 'OK/components/inspectionLog/archiveCard';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LoadingSpinner from 'OK/components/loadingSpinner';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import AddWorkPopup from 'OK/modules/popups/addWork';
import DatePickerPopup from 'OK/modules/popups/datePicker';
import {
  GetInspectionLogsForOrganisationQuery,
  GetNumberOfInspectionLogsPerDayForOrganisationQuery,
} from 'OK/networking/inspectionLogs';
import { searchQuery } from 'OK/networking/search';
import { formatToUTCUnixTimestampString } from 'OK/networking/utils';
import { generateAreaChartConfig } from 'OK/util/chartjs/config';
import { formatAreaChartDataset } from 'OK/util/chartjs/format';
import ChartJSHtmlLegendPlugin from 'OK/util/chartjs/htmlLegendPlugin';
import ThemeContext from 'OK/util/context/theme';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatDate, formatOkid } from 'OK/util/formatting';
import { getFirstMomentOfDate, getLastMomentOfDate } from 'OK/util/functions/date';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission, { OBJECT_PERMISSIONS } from 'OK/util/hooks/usePermission';

if (typeof window !== 'undefined') {
  Chart.register(...registerables, annotationPlugin, ChartJSHtmlLegendPlugin);
}

const LIST_DISPLAY_TYPE = {
  LOGS: 'LOGS',
  DOCUMENTS: 'DOCUMENTS',
};
const PAGE_SIZE = 10;

function formatDateKey(date) {
  // eslint-disable-next-line quotes
  return DateFnsFormatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSS+00:00");
}

const OrganisationLogHistorySection = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { className, flexHeader, organisation, showSection, toggleSection, user, ...otherProps } = props;
  const { locale, t, tHTML } = useI18n();
  const theme = useContext(ThemeContext);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const [, , currentUser] = useAuthentication(() => false);
  const canViewInternalLogs = usePermission(
    OBJECT_PERMISSIONS.VIEW_ORGANISATION_INTERNAL_LOGS,
    currentUser,
    activeOrganisationId,
    organisation
  );
  const authorisationLevel = useAuthorisationLevel(organisation);
  const hasManagementRights = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);

  /* State */

  // Default values
  const filterEndDateDefault = getLastMomentOfDate(new Date()); // Today
  const filterStartDateDefault = getFirstMomentOfDate(subtractFromDate(filterEndDateDefault, { weeks: 1 })); // 1 week ago

  const [chartFiltersState, setChartFilters] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(filterStartDateDefault);
  const [filterEndDate, setFilterEndDate] = useState(filterEndDateDefault);
  const [listDisplayType, setListDisplayType] = useState(LIST_DISPLAY_TYPE.LOGS);
  const [newFilterSearchString, setNewFilterSearchString] = useState('');
  const [renderAddWorkPopup, setRenderAddWorkPopup] = useState(false);
  const [renderDatePicker, setRenderDatePicker] = useState(false);
  const [renderNewChartFilters, setRenderNewChartFilters] = useState(false);

  // Computed state
  const eachDateInRange = eachDayOfInterval({ start: filterStartDate, end: filterEndDate });
  const hasSelectedRange = !datesAreEqual(filterStartDate, filterEndDate);
  const chartFilters = [...chartFiltersState];
  if (user) {
    chartFilters.unshift({
      dataType: 'USER',
      dataId: user.id,
      label: user.name ?? formatOkid(user.OKID),
      removeable: false,
    });
  }

  /* API */

  const filterByInspectorIdList = chartFilters.filter((f) => f.dataType === 'USER').map((f) => f.dataId);
  const filterBySourceIdList = chartFilters
    .filter((f) => f.dataType === 'PRODUCT' || f.dataType === 'SITE')
    .map((f) => f.dataId);

  const organisationLogsAPIResult = useQuery(GetInspectionLogsForOrganisationQuery, {
    errorPolicy: 'all',
    variables: {
      endDateUTCString: formatToUTCUnixTimestampString(filterEndDate),
      filterByInspectorIdList,
      filterBySourceIdList,
      includeInternalLogs: canViewInternalLogs,
      limit: PAGE_SIZE,
      organisationId: organisation.id,
      skip: 0,
      startDateUTCString: formatToUTCUnixTimestampString(filterStartDate),
    },
  });
  const organisationLogHistory = useQuery(GetNumberOfInspectionLogsPerDayForOrganisationQuery, {
    variables: {
      endDateUTCString: formatToUTCUnixTimestampString(filterEndDate),
      filterByInspectorIdList,
      filterBySourceIdList,
      includeInternalLogs: canViewInternalLogs,
      organisationId: organisation.id,
      startDateUTCString: formatToUTCUnixTimestampString(filterStartDate),
    },
  });
  const [getDataIndicatorsAPI, getDataIndicatorsAPIResult] = useLazyQuery(
    GetNumberOfInspectionLogsPerDayForOrganisationQuery
  );
  const filterSearchApiResult = useQuery(searchQuery, {
    variables: {
      searchPaginationDataByDataType: [
        {
          dataType: 'PRODUCT',
          searchPaginationData: { pageSize: 4, skip: 0 },
        },
        {
          dataType: 'SITE',
          searchPaginationData: { pageSize: 4, skip: 0 },
        },
        {
          dataType: 'USER',
          searchPaginationData: { pageSize: 4, skip: 0 },
        },
      ],
      searchString: newFilterSearchString,
    },
    skip: newFilterSearchString.length < 2,
  });

  const numberOfLogsLoaded = organisationLogsAPIResult.data?.result?.inspectionLogList?.length ?? 0;
  const canLoadMoreLogs = organisationLogsAPIResult.data?.result?.totalResults > numberOfLogsLoaded;
  const chartListSlides =
    organisationLogsAPIResult.data?.result?.inspectionLogList?.map((l) => {
      if (listDisplayType === LIST_DISPLAY_TYPE.DOCUMENTS) {
        // Show list of documents for each log
        return l.inspectionLogDocumentAssetList.map((d) => {
          const documentAsset = d.documentAsset;
          return (
            <Slide className={styles.logSlide} key={documentAsset.id}>
              <DocumentArchiveCard
                cardClassName={styles.logCardInner}
                className={styles.log}
                fixedWidth={useDesktopLayout}
                documentAsset={documentAsset}
              />
            </Slide>
          );
        });
      }

      return (
        <Slide className={styles.logSlide} key={l.id}>
          <InspectionLogArchiveCard
            cardClassName={styles.logCardInner}
            className={styles.log}
            fixedWidth={useDesktopLayout}
            inspectionLog={l}
            showLinkToProduct={false}
          />
        </Slide>
      );
    }) ?? [];
  const numberOfListSlides = Children.count(chartListSlides);
  let hasMoreFilterSearchResults = false;
  if (filterSearchApiResult.data?.search?.searchPaginationResultDataByDataType) {
    const keys = Object.keys(filterSearchApiResult.data.search.searchPaginationResultDataByDataType);
    for (let key of keys) {
      const paginationData = filterSearchApiResult.data.search.searchPaginationResultDataByDataType[key];
      if (paginationData.totalResults > paginationData.pageResults) {
        hasMoreFilterSearchResults = true;
      }
    }
  }

  /* Methods */

  const closeAddWorkPopup = useCallback(() => {
    setRenderAddWorkPopup(false);
  }, []);

  const closeDatePicker = useCallback(() => {
    setRenderDatePicker(false);
  }, []);

  const getDataIndicators = useCallback(
    (startDate, endDate) => {
      if (dateIsInTheFuture(startDate)) {
        return;
      }

      getDataIndicatorsAPI({
        variables: {
          endDateUTCString: formatToUTCUnixTimestampString(endDate),
          includeInternalLogs: canViewInternalLogs,
          organisationId: organisation.id,
          startDateUTCString: formatToUTCUnixTimestampString(startDate),
        },
      });
    },
    [canViewInternalLogs, getDataIndicatorsAPI, organisation.id]
  );

  const loadMoreLogs = useCallback(() => {
    if (!organisationLogsAPIResult.called || organisationLogsAPIResult.loading) {
      return;
    }

    organisationLogsAPIResult.fetchMore({
      variables: {
        skip: numberOfLogsLoaded,
      },
    });
  }, [numberOfLogsLoaded, organisationLogsAPIResult]);

  const openAddWorkPopup = useCallback(() => {
    setRenderAddWorkPopup(true);
  }, []);

  const removeChartFilter = useCallback((filter) => {
    setChartFilters((currentFilters) => {
      const indexToRemove = currentFilters.findIndex(
        (f) => f.dataId === filter.dataId && f.dataType === filter.dataType
      );
      if (indexToRemove > -1) {
        const updatedFilters = [...currentFilters];
        updatedFilters.splice(indexToRemove, 1);
        return updatedFilters;
      }

      return currentFilters;
    });
  }, []);

  const showDatePicker = useCallback(() => {
    setRenderDatePicker(true);
  }, []);

  /* Event handlers */

  const onCarouselScroll = useCallback(
    (visibleSlideIndexes) => {
      if (!visibleSlideIndexes.length || numberOfListSlides === 0) {
        return;
      }
      const lastVisibleSlideIndex = visibleSlideIndexes[visibleSlideIndexes.length - 1];
      if (lastVisibleSlideIndex === numberOfListSlides) {
        if (canLoadMoreLogs) {
          loadMoreLogs();
        }
      }
    },
    [canLoadMoreLogs, numberOfListSlides, loadMoreLogs]
  );

  const onChangeDateRangeFilter = useCallback(
    (startDate, endDate) => {
      const newStartDate = startDate;
      const newEndDate = endDate ?? getLastMomentOfDate(startDate);
      setFilterStartDate(newStartDate);
      setFilterEndDate(newEndDate);
      closeDatePicker();
    },
    [closeDatePicker]
  );

  const onChangeNewFilterSearchString = useCallback((e) => {
    setNewFilterSearchString(e.target.value);
  }, []);

  const onClickFilterSuggestion = useCallback(
    (dataId) => {
      const asset = filterSearchApiResult.data.search.resultList.find((r) => r.dataId === dataId);
      let newFilter;
      if (asset.dataType === 'PRODUCT') {
        newFilter = {
          dataId: asset.dataId,
          dataType: asset.dataType,
          label: ProductModel.localizedNameForProduct(asset.productData, locale),
          removeable: true,
        };
      } else if (asset.dataType === 'SITE') {
        newFilter = {
          dataId: asset.dataId,
          dataType: asset.dataType,
          label: SiteModel.localizedNameForSite(asset.siteData, locale),
          removeable: true,
        };
      } else if (asset.dataType === 'USER') {
        newFilter = {
          dataId: asset.dataId,
          dataType: asset.dataType,
          label: asset.userData.name ?? t('USER'),
          removeable: true,
        };
      }

      if (newFilter) {
        setChartFilters((currentFilters) => [...currentFilters, newFilter]);
      }

      setNewFilterSearchString('');
      setRenderNewChartFilters(false);
    },
    [filterSearchApiResult.data?.search?.resultList, locale, t]
  );

  const toggleNewFilterOptions = useCallback(() => {
    setRenderNewChartFilters((current) => !current);
  }, []);

  /* Render */

  const formattedStartDate = formatDate(filterStartDate, locale, { style: 'short' });
  let formattedEndDate;
  if (hasSelectedRange) {
    formattedEndDate = formatDate(filterEndDate, locale, { style: 'short' });
  }

  // Log history chart
  /* eslint-disable indent */
  const chartLabels = eachDateInRange.map((date) => formatDate(date, locale, { style: 'short' }));
  if (chartLabels.length === 1) {
    // Insert empty labels at beginning and end so chart renders
    chartLabels.unshift('');
    chartLabels.push('');
  }
  const numberOfLogsPerDay = organisationLogHistory.data
    ? eachDateInRange.map((date) => {
        // eslint-disable-next-line quotes
        const dateString = formatDateKey(date);
        return {
          date,
          numberOfLogs: organisationLogHistory.data.inspectionLogHistory[dateString] ?? 0,
        };
      })
    : [];
  if (numberOfLogsPerDay.length === 1) {
    // Insert empty data points at beginning and end so chart renders
    numberOfLogsPerDay.unshift({ date: filterStartDate, numberOfLogs: 0 });
    numberOfLogsPerDay.push({ date: filterStartDate, numberOfLogs: 0 });
  }
  const numberOfLogsPerDayChartDataset = organisationLogHistory.data
    ? formatAreaChartDataset(
        numberOfLogsPerDay.map((d, index) => {
          const dataPoint = {
            x: index,
            y: d.numberOfLogs,
          };
          return dataPoint;
        }),
        t('NUMBER_OF_LOGS'),
        {
          chartHeight: 250,
          dataBackgroundColorRGBA: 'rgba(80, 124, 133, 1)',
          hideTooltip: false,
        }
      )
    : null;
  const numberOfLogsPerDayChartConfig = numberOfLogsPerDayChartDataset
    ? generateAreaChartConfig(
        {
          datasets: [numberOfLogsPerDayChartDataset],
          labels: chartLabels,
        },
        {
          legendContainerId: 'legendContainerId',
          maxYValue: Math.max(...numberOfLogsPerDay.map((n) => n.numberOfLogs)) * 1.05,
          theme,
          tooltipLabelCallback: (tooltip) => {
            if (tooltip.dataset.hideTooltip) {
              return null;
            }
            if (typeof tooltip.parsed.x === 'undefined' || tooltip.parsed.x === null) {
              return t('NO_DATA');
            }
            return chartLabels[tooltip.parsed.x];
          },
          options: {
            scales: {
              x: {
                grid: {
                  display: true,
                  drawTicks: true,
                  lineWidth: 0,
                  tickColor: theme.colors.brand,
                  tickWidth: 1,
                },
                ticks: {
                  callback: (tickValue, index) => {
                    if (index % 2 !== 0) {
                      // Show labels for odd ticks
                      const roundedIndex = parseInt(tickValue, 10);
                      return chartLabels[roundedIndex];
                    }

                    return '';
                  },
                  color: '#767575',
                  count: useMobileLayout ? 3 : 7,
                  display: true,
                  font: {
                    size: 14,
                  },
                  padding: 0,
                },
              },
              y: {
                grid: {
                  color: theme.colors.contentBackgroundCard,
                },
                position: 'right',
                ticks: {
                  callback: (tickValue, index) => {
                    if (index === 1 || index === 5 || index === 9) {
                      return Math.round(tickValue);
                    }

                    return '';
                  },
                  color: theme.colors.brand,
                  count: 11,
                  display: true,
                  font: {
                    size: 14,
                    weight: 'bold',
                  },
                  mirror: true,
                  padding: 0,
                },
              },
            },
          },
        }
      )
    : null;
  /* eslint-enable indent */

  // Data indicators for date picker
  let datePickerDataIndicators;
  if (getDataIndicatorsAPIResult.data?.inspectionLogHistory) {
    const dates = Object.keys(getDataIndicatorsAPIResult.data.inspectionLogHistory).sort();
    if (dates.length) {
      const firstDateWithData = new Date(dates[0]);
      const wholeYearInterval = {
        start: startOfYear(firstDateWithData),
        end: lastDayOfYear(firstDateWithData),
      };
      datePickerDataIndicators = eachDayOfInterval(wholeYearInterval).map((date) => {
        const dateKey = formatDateKey(date);
        return {
          date,
          hasData: getDataIndicatorsAPIResult.data.inspectionLogHistory[dateKey] > 0,
        };
      });
      console.log("datePickerDataIndicators", datePickerDataIndicators);
    }
  }

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  // Trigger the UI change for logs from cache on page load.
  useEffect(() => {
    organisationLogsAPIResult.refetch({
      fetchPolicy: 'cache-only',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Accordion
        className={styles.accordion}
        fixedWidth={false}
        onChangeOpen={toggleSection}
        headerClassName={flexHeader ? styles.headerAccordion : styles.headerAccordionInline}
        hideSection
        open={showSection}
        title={
          <div className={styles.header}>
            <Icon className={styles.icon} name={ICONS.COG.name} height={24} width={24} />
            &nbsp;&nbsp;
            {t('WORK')}
          </div>
        }
        toggleButtonClassname={flexHeader && styles.toggleButton}
      >
        <ContentLayout className={classNames} ref={forwardedRef} {...otherProps} pageContent>
          {hasManagementRights && (
            <Button
              block
              className={styles.beginWorkButton}
              icon={ICONS.INSPECTION_LOG.name}
              onClick={openAddWorkPopup}
              tint='creation'
            >
              {t('BEGIN_WORK')}
            </Button>
          )}
          <div className={styles.filterChartSection}>
            <h4>{t('LOG_HISTORY_SECTION')}</h4>
            <Text className={styles.sectionDescription}>{tHTML('LOG_HISTORY_SECTION_DESCRIPTION')}</Text>
            <h5>{t('FILTER(S)')}</h5>
            {chartFilters.map((f) => {
              return (
                <div className={styles.chartFilter} key={f.dataId}>
                  <Text className={styles.chartFilterName} size='sm'>
                    {t(f.dataType)}: {f.label}
                  </Text>
                  {f.removeable && (
                    <>
                      <button className={styles.chartFilterRemoveButton} onClick={() => removeChartFilter(f)}>
                        <Icon height={14} name={ICONS.X_SMALL.name} width={14} />
                      </button>
                    </>
                  )}
                </div>
              );
            })}
            {renderNewChartFilters ? (
              <div className={styles.newFilterContainer}>
                <div className={styles.newFilterSearchContainer}>
                  <Input
                    className={styles.searchInput}
                    onChange={onChangeNewFilterSearchString}
                    placeholder={t('SEARCH_FOR_SOMETHING')}
                    value={newFilterSearchString}
                  />
                  {filterSearchApiResult.data?.search?.resultList?.length > 0 && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      onSuggestionClick={onClickFilterSuggestion}
                      showMoreResultsMessage={hasMoreFilterSearchResults}
                      showNoResultsMessage={!filterSearchApiResult.data?.search?.resultList?.length}
                      suggestions={filterSearchApiResult.data.search.resultList.map((r) => {
                        let icon;
                        let subtitle;
                        let title;
                        if (r.dataType === 'PRODUCT') {
                          icon = ICONS.PRODUCT.name;
                          title = ProductModel.localizedNameForProduct(r.productData, locale);
                          subtitle = r.productData.REFID;
                        } else if (r.dataType === 'SITE') {
                          icon = ICONS.PRODUCT.name;
                          title = SiteModel.localizedNameForSite(r.siteData, locale);
                          subtitle = formatOkid(r.siteData.OKID);
                        } else if (r.dataType === 'USER') {
                          icon = ICONS.PROFILE.name;
                          title = r.userData.name ?? t('USER');
                          subtitle = formatOkid(r.userData.OKID);
                        }
                        return {
                          icon,
                          key: r.dataId,
                          subtitle,
                          title,
                        };
                      })}
                    />
                  )}
                </div>
                <Button linkStyle onClick={toggleNewFilterOptions}>
                  {t('CANCEL')}
                </Button>
              </div>
            ) : (
              <Button
                className={styles.addChartFilterButton}
                icon={ICONS.PLUS.name}
                linkStyle
                onClick={toggleNewFilterOptions}
                tint='creation'
              >
                {t('ADD')}
              </Button>
            )}
          </div>
          <div className={styles.selectedDateRangeContainer}>
            <Text bold className={styles.selectedDateRange}>
              {hasSelectedRange ? `${formattedStartDate} - ${formattedEndDate}` : formattedStartDate}
            </Text>
            <Button linkStyle onClick={showDatePicker}>
              {t('CHANGE')}
            </Button>
          </div>
          {numberOfLogsPerDayChartConfig && (
            <div className={styles.chartContainer}>
              <LineChart data={numberOfLogsPerDayChartConfig.data} options={numberOfLogsPerDayChartConfig.options} />
              <div id='legendContainerId' />
            </div>
          )}
          <h5 style={{ marginBottom: '10px' }}>{t('LOG_LOGS_AND_ATTACHMENTS_SECTION')}</h5>
          <Text className={styles.sectionDescription}>{tHTML('LOG_LOGS_AND_ATTACHMENTS_SECTION_DESCRIPTION')}</Text>
          <ButtonGroup className={styles.listDisplayTypeButton}>
            <button
              active={listDisplayType === LIST_DISPLAY_TYPE.LOGS}
              onClick={() => setListDisplayType(LIST_DISPLAY_TYPE.LOGS)}
            >
              {t('LOGS')}
            </button>
            <button
              active={listDisplayType === LIST_DISPLAY_TYPE.DOCUMENTS}
              onClick={() => setListDisplayType(LIST_DISPLAY_TYPE.DOCUMENTS)}
            >
              {t('PRODUCT_SECTION_DOCUMENTATION')}
            </button>
          </ButtonGroup>

          <Carousel
            className={styles.logsCarousel}
            innerClassName={styles.carousel}
            fadeOutSides={useDesktopLayout}
            onChangeVisibleSlides={onCarouselScroll}
            snapToSlides={false}
            visibilityDivider={3}
          >
            {chartListSlides}
            {canLoadMoreLogs && (
              <Slide className={styles.loadingMoreSlide}>
                <LoadingSpinner className={styles.loadingMoreIcon} />
              </Slide>
            )}
          </Carousel>
          <TextLayout>
            {numberOfLogsLoaded === 0 && (
              <Text size='sm' tint='notification'>
                {t('NO_LOGS_IN_SELECTED_RANGE')}
              </Text>
            )}
          </TextLayout>
        </ContentLayout>
      </Accordion>

      {renderDatePicker && (
        <DatePickerPopup
          dataIndicators={datePickerDataIndicators}
          dataIndicatorsDescription={t('LOGS_AVAILABLE')}
          dismiss={closeDatePicker}
          getDataIndicatorsForDateRange={getDataIndicators}
          initialStartDate={filterStartDate}
          initialEndDate={filterEndDate}
          onSave={onChangeDateRangeFilter}
        />
      )}
      {renderAddWorkPopup && <AddWorkPopup dismiss={closeAddWorkPopup} />}
    </>
  );
});

export default OrganisationLogHistorySection;

OrganisationLogHistorySection.propTypes = {
  className: PropTypes.string,
  flexHeader: PropTypes.bool,
  organisation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  showSection: PropTypes.bool,
  toggleSection: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    OKID: PropTypes.string.isRequired,
  }),
};
