var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ok-grade-web@v3.5.17"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const appEnv = process.env.NEXT_PUBLIC_APP_ENV;

Sentry.init({
  beforeSend: (event) => {
    if (event.request?.url) {
      const url = event.request.url;
      // Only send events from the dev or production site.
      if (/^https:\/\/(?:www|dev|staging)?\.?(?:okgrade\.com|oktrade\.org)/.test(url)) {
        return event;
      }
    }

    return null;
  },
  dsn: 'https://197b377f978445e6ae2aaa6ea23b9b39@o716927.ingest.sentry.io/5779752',
  environment: appEnv,
  normalizeDepth: 5,
});
